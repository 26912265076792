
import { defineComponent, computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  props: {
    content: {
      type: Object,
      required: true,
    },
    current: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const i18n = useI18n()

    return {
      filename: computed(() => {
        const lang = i18n.locale.value as 'ja' | 'en'
        const contents = props.content.contents
        if (!contents) return ''
        if (contents.name && (contents.name.ja || contents.name.en)) {
          // 例）langがenのとき、name[en]がemptyならname[ja]が表示される
          return contents.name?.[lang] || contents.name?.ja || contents.name?.en
        }
        return props.content.contents.originalFileName || ''
      }),
      onClick: () => {
        emit('onClickSnippet', props.content)
      },
    }
  },
})
